import React, { useState, useEffect } from 'react';
import popup from '../style/Popup.css';
import Popup from 'reactjs-popup';

export default function PopupC(props) {

  const [value, setValue] = useState("");

  const handleSubmit = () => {
    props.contact(value);
    setValue("");
    props.closeModal();
  }

  const handleChange = (event) => {
    setValue(event.target.value);
  }

  // useEffect(() => {
  //   const node = document.getElementById("input");
  //   console.log(node);
  //   if (node) {
  //     node.addEventListener("keyup", ({key}) => {
  //       if (key === "Enter") {
  //         handleSubmit()
  //       }
  //     })
  //   }
  // }, [props.open]);

  return (
    <Popup class="popup" open={ props.open } closeOnDocumentClick onClose={ props.closeModal }>
      <div className="modal">
        <a className="close" onClick={ props.closeModal }>
          &times;
        </a>
        <div id="form">
          <img width="100" height="100" src={require("./blank.png")}></img>
          <div class="number">{props.number}</div>
          Enter contact name:<br/>
          <input
            id="input"
            value={value}
            onChange={handleChange}
          ></input><br/>
          <button type="submit" onClick={handleSubmit}>Submit</button>
        </div>
      </div>
    </Popup>
  );
}