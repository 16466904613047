import React, { Component } from 'react';
import '../style/SMSForm.css';
import '../App.css';
import PopupC from './AddContact.js';

class SMSForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: {
        to: '',
        from: props.phone,
        body: ''
      },
      currContact: "",
      history: '',
      phones: [null],
      submitting: false,
      error: false,
      display: {},
      editing: false,
      popOpen: false,
      bodyState: "unfocused",
      justSent: 0,
    };
    this.onHandleChange = this.onHandleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.update();
  }



  update() {
    const gfg_token_header_key = localStorage.getItem('gfg_token_header_key');
    this.updateHistory();
    //console.log("testing header", localStorage.getItem('gfg_token_header_key'));
    fetch(process.env.REACT_APP_API_URL + '/db/contacts', {
      method: 'POST',
      headers: {
        'gfg_token_header_key': gfg_token_header_key,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: this.props.id,
      })
    })
      .then(res => res.json())
      .then(data => {
        // console.log(data.out);
        let phoneArr = [];
        data.out.forEach((row) => {
          phoneArr.push(row.candidate_number);
        });
        this.setState({ phones: phoneArr });
        data.out.forEach((row) => {
          fetch(process.env.REACT_APP_API_URL + '/api/last', {
            // fetch(process.env.REACT_APP_API_URL+'/api/last', {
            method: 'POST',
            headers: {
              'gfg_token_header_key': gfg_token_header_key,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              to: row.candidate_number,
              from: this.state.message.from
            })
          })
            .then(res => res.json())
            .then(data => {
              if (data.success) {
                if (data.out === undefined) {
                  let newtime = (new Date().toISOString()).replace(/T|Z/g, ' ');
                  newtime = newtime.substring(0, newtime.indexOf("."));
                  this.setState({
                    display: { ...this.state.display, [row.candidate_number]: ["", newtime, newtime, row.contact] }
                  })
                } else {
                  let newtime = (data.out.dateSent).replace(/T|Z/g, ' ');
                  newtime = newtime.substring(0, newtime.indexOf("."));
                  this.setState({
                    display: { ...this.state.display, [row.candidate_number]: [data.out.body, newtime, row.last_read, row.contact] }
                  })
                  if (newtime > row.updated_at) {
                    fetch(process.env.REACT_APP_API_URL + '/db/setupdate', {
                      method: 'POST',
                      headers: {
                        'gfg_token_header_key': gfg_token_header_key,
                        'Content-Type': 'application/json'
                      },
                      body: JSON.stringify({
                        phone: this.state.message.from,
                        to: row.candidate_number,
                        time: newtime
                      })
                    })
                      .catch(err => {
                        console.log(err);
                      });
                  }
                }

              }
            })
            .catch(err => {
              console.log(err);
            });
        });
        // Make the second POST request with bodyParams2
        fetch(process.env.REACT_APP_API_URL + '/api/last', {
          // fetch(process.env.REACT_APP_API_URL+'/api/last', {
          method: 'POST',
          headers: {
            'gfg_token_header_key': gfg_token_header_key,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            from: this.state.message.from,
            status: "last_msg"
          })
        })
          .then(response => response.json())
          .catch(error => console.error('Error fetching data:', error));

      })
      .catch(err => {
        console.log(err);
      });
  };

  updateHistory() {
    const gfg_token_header_key = localStorage.getItem('gfg_token_header_key');
    if (this.state.justSent > 0) {
      this.setState({ justSent: this.state.justSent - 1 });
    } else {
      const atBottom = window.scrollY + window.innerHeight > document.body.scrollHeight;
      // console.log(window.scrollY + window.innerHeight);
      // console.log(document.body.scrollHeight);
      fetch(process.env.REACT_APP_API_URL + '/api/load', {
        method: 'POST',
        headers: {
          'gfg_token_header_key': gfg_token_header_key,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          from: this.state.message.from
        })
      })
        .then(res => res.json())
        .then(data => {
          //console.log(data.out);

          if (data.success) {
            // console.log(data.thing);
            if (this.state.justSent === 0) {
              this.setState({ history: data.out }, () => {
                if (atBottom) {
                  //window.scrollTo(0, document.body.scrollHeight);
                  this.setRead();
                }
              });
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  onHandleChange(event) {
    const name = event.target.getAttribute('name');
    if (name === "to") {
      this.setState({
        currContact: event.target.value
      }, () => {
        // console.log(this.state.phones.includes(this.state.message.to));
        this.setRead();
      });
    } else {
      const field = document.getElementById("body");
      this.setState({
        message: { ...this.state.message, body: field.innerText }
      }, () => {
        // console.log(this.state.phones.includes(this.state.message.to));
        this.setRead();
      });
    }
  }

  onSubmit(event) {
    const gfg_token_header_key = localStorage.getItem('gfg_token_header_key');
    event.preventDefault();

    if (this.state.submitting || this.state.message.body === "") {
      return;
    }

    console.log('submitting');
    // console.log(this.state.message);

    this.setState({ submitting: true, justSent: 2 });
    let arr = this.state.history;
    arr.unshift({
      to: this.state.message.to,
      from: this.state.message.from,
      direction: "outbound",
      dateSent: (new Date().toISOString()),
      body: this.state.message.body
    })
    this.setState({ history: arr }, () => { window.scrollTo(0, document.body.scrollHeight); });
    document.getElementById("body").innerText = "";

    fetch(process.env.REACT_APP_API_URL + '/api/messages', {
      method: 'POST',
      headers: {
        'gfg_token_header_key': gfg_token_header_key,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.state.message)
    })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          console.log("worked");
          this.setState({
            ...this.state,
            error: false,
            submitting: false
          });

          if (!this.state.phones.includes(this.state.message.to)) {
            fetch(process.env.REACT_APP_API_URL + '/db/new', {
              method: 'POST',
              headers: {
                'gfg_token_header_key': gfg_token_header_key,
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ id: this.props.id, phone: this.state.message.from, to: this.state.message.to, contact: "null" })
            })
              .then(res => res.json())
              .then(data => {
                console.log("done?");
                // console.log(data);
                let newtime = (new Date().toISOString()).replace(/T|Z/g, ' ');
                newtime = newtime.substring(0, newtime.indexOf("."));
                this.setState({
                  display: { ...this.state.display, [this.state.message.to]: [this.state.message.body, newtime, newtime] }
                });
                // this.state.display[this.state.message.to] = [this.state.message.body, newtime, newtime];

                let arr = this.state.phones;
                arr.unshift(this.state.message.to);
                console.log("new!!!");
                this.setState({ phones: arr });

                console.log("worked");
                this.setState({
                  ...this.state,
                  error: false,
                  submitting: false,
                  message: {
                    ...this.state.message,
                    body: ''
                  }
                });
                document.getElementById("body").innerText = "";
              })
          } else {
            console.log("not new");
            fetch(process.env.REACT_APP_API_URL + '/db/update', {
              method: 'POST',
              headers: {
                'gfg_token_header_key': gfg_token_header_key,
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                phone: this.state.message.from,
                to: this.state.message.to,
              })
            })
              .then(() => {
                this.setState({
                  ...this.state,
                  error: false,
                  submitting: false,
                  message: {
                    ...this.state.message,
                    body: ''
                  }
                });
                this.setRead();
                // document.getElementById("body").innerText = "";
              })
              .catch(err => {
                console.log(err);
              })
          }
        } else {
          this.setState({
            error: true,
            submitting: false
          });
        }
        // this.updateHistory();
      });
  }

  componentDidMount() {
    const body = document.getElementById("body");
    body.addEventListener("keydown", this.submitOnEnter);
    body.addEventListener("click", () => {
      if (body.innerText === "Message") {
        body.innerText = "";
        this.setState({ bodyState: "focused" });
      }
    });
    body.addEventListener('blur', () => {
      if (body.innerText === "") {
        body.innerText = "Message"
        this.setState({ bodyState: "unfocused" });
      }
    });
    this.interval = setInterval(() => { this.update(); this.forceUpdate(); }, 3000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  loadNumber(number) {
    // console.log(this.state.display[number][3]);
    this.setState({
      message: { ...this.state.message, to: number },
      currContact: this.state.display[number][3] ? this.state.display[number][3] : number
    }, () => {
      this.setRead();
      window.scrollTo(0, document.body.scrollHeight);
      const body = document.getElementById("body");
      body.focus();
      if (body.innerText === "Message") {
        body.innerText = "";
        this.setState({ bodyState: "focused" });
      }
    });
  }

  setRead() {
    const gfg_token_header_key = localStorage.getItem('gfg_token_header_key');
    if (this.state.phones.includes(this.state.message.to)) {
      fetch(process.env.REACT_APP_API_URL + '/db/setread', {
        method: 'POST',
        headers: {
          'gfg_token_header_key': gfg_token_header_key,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          phone: this.state.message.from,
          to: this.state.message.to,
          id: this.props.id
        })
      })
    }
  }

  newPhone() {
    console.log("clicked");
    this.setState({
      editing: true,
      message: { ...this.state.message, to: "" },
      currContact: "+1"
    });
    document.getElementsByName("to")[0].focus();
  }

  unclicked() {
    const tofield = document.getElementsByName("to")[0];
    // console.log("valid?");
    // console.log(tofield.checkValidity());
    if (tofield.value === "+1") {
      this.setState({
        message: { ...this.state.message, to: "" },
        currContact: "",
        editing: false
      });
    } else {
      // console.log(tofield.value);
      if (tofield.checkValidity()) {
        this.setState({
          message: { ...this.state.message, to: tofield.value },
          editing: false
        });
        if (this.state.display[tofield.value]) {
          this.setState({ currContact: this.state.display[tofield.value][3] ? this.state.display[tofield.value][3] : tofield.value })
        }
      } else {
        tofield.reportValidity();
      }
    }

  }

  verifyDisplay() {
    const keys = this.state.phones;
    if (Object.keys(this.state.display).length === 0) {
      return false;
    }
    return keys.every((key) => this.state.display[key] !== undefined);
  }

  createContact() {
    this.setState({ popOpen: true })
  }

  setContact(value) {
    const gfg_token_header_key = localStorage.getItem('gfg_token_header_key');
    console.log("got value!!");
    // const value = val.replaceAll("'", "-/-/-");
    // console.log(value);
    if (value !== "" && value !== undefined) {
      this.setState({ currContact: value });
      if (!this.state.phones.includes(this.state.message.to)) {
        fetch(process.env.REACT_APP_API_URL + '/db/new', {
          method: 'POST',
          headers: {
            'gfg_token_header_key': gfg_token_header_key,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ id: this.props.id, phone: this.state.message.from, to: this.state.message.to, contact: value })
        })
          .then(res => res.json())
          .then(data => {
            console.log("done?");
            // console.log(data);
            let newtime = (new Date().toISOString()).replace(/T|Z/g, ' ');
            newtime = newtime.substring(0, newtime.indexOf("."));
            this.setState({
              display: { ...this.state.display, [this.state.message.to]: [this.state.message.body, newtime, newtime, value] }
            });
            // this.state.display[this.state.message.to] = [this.state.message.body, newtime, newtime, val];

            let arr = this.state.phones;
            arr.unshift(this.state.message.to);
            console.log("new!!!");
            this.setState({ phones: arr });

            console.log("worked");
          });
      } else {
        fetch(process.env.REACT_APP_API_URL + '/db/newcontact', {
          method: 'POST',
          headers: {
            'gfg_token_header_key': gfg_token_header_key,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            contact: value,
            phone: this.state.message.from,
            to: this.state.message.to,
            id: this.props.id
          })
        })
      }
    }
  }

  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + '' + ampm;
    return strTime;
  }

  filterHistory() {
    if (this.state.history !== "") {
      let arr = [];
      let date = "";
      let currDate = new Date().toDateString();
      let yestDate = new Date(new Date().setDate(new Date().getDate() - 1)).toDateString();

      arr.push(<><br /><br /></>);

      this.state.history.toReversed().forEach((inp) => {
        if ((inp.to === this.state.message.to && inp.from === this.state.message.from) || (inp.from === this.state.message.to && inp.to === this.state.message.from)) {
          const modDate = new Date(inp.dateSent).toDateString();
          if (date !== modDate) {
            if (modDate === currDate) {
              arr.push(<div id="date"> Today </div>);
              date = modDate;
            } else if (modDate === yestDate) {
              arr.push(<div id="date"> Yesterday </div>);
              date = modDate;
            } else {
              arr.push(<div id="date"> {modDate} </div>);
              date = modDate;
            }
          }

          arr.push(<>
            <div id={`${inp.direction === "inbound" ? "inbound" : inp.status === "undelivered" ? "undelivered" : "outbound"}`} class="text">
              {/* <div class={inp.status}> */}
              {inp.body}
              {/* </div> */}
            </div>
            {inp.status === "undelivered" ? <div class="notice">undelivered</div> : ""}
            <div class="smalltime" id={`${inp.direction === "inbound" ? "intime" : "outtime"}`}>
              {this.formatAMPM(new Date(inp.dateSent))}
            </div>
          </>);
        }
      })

      arr.push(<><br /><br /><br /><br /></>);

      return arr;
    }

    return (<></>);
  }

  submitOnEnter(event) {
    if (event.which === 13) {
      if (!event.repeat) {
        const newEvent = new Event("submit", { cancelable: true });
        document.getElementById("yesthisform").dispatchEvent(newEvent);
      }
      // console.log("yas kween enter");
      event.preventDefault(); // Prevents the addition of a new line in the text field
    }
  }

  render() {
    return (
      <>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <div id="scroll">
          <div class="messages-title">Messages</div>
          <button class="btn" title="New Message" onClick={() => { this.newPhone() }}><i class="fa fa-plus"></i></button>

          {this.state.phones.length === 0 ? <p>No new messages</p> :
            !this.verifyDisplay() ? <p>Loading...</p> : <>
              {(this.state.phones).map((num) =>
                <button class={`click ${this.state.display[num][1] > this.state.display[num][2] ? "unread" : "read"} ${num === this.state.message.to ? "selected" : ""}`} onClick={() => { this.loadNumber(num) }}>
                  <b style={{ fontSize: "18px" }}>{this.state.display[num][3] ? this.state.display[num][3] : num}</b> <br />
                  <p>{this.state.display[num][0]}</p> <br />
                </button>
              )}<br /><br /><br /><br /></>}
        </div>


        <header className="App-header">
          <div class="main">

            {this.state.message.to === "" ? <></> : <button id="info" title="Contact Info" onClick={() => { this.createContact() }} ><b>i</b></button>}
            <div id="email">{this.props.name}</div><br /><br />
            <div id="namebar">dajhsdajdhasjdha</div>
            <PopupC number={this.state.message.to} contact={(value) => { this.setContact(value) }} open={this.state.popOpen} closeModal={() => { this.setState({ popOpen: false }) }}></PopupC>

            {this.filterHistory()}

            <div class="form">
              <form
                id="yesthisform"
                onSubmit={this.onSubmit}
                className={this.state.error ? 'error sms-form' : 'sms-form'}
              >
                <div id="out"><div id="to_div">
                  <div class="innerto"><label htmlFor="to">To:</label></div>
                  <div class={`innerto ${this.state.editing ? "" : "notediting"}`} ><input
                    type="tel"
                    name="to"
                    id="to"
                    pattern="(\+1)\d{10}"
                    value={this.state.currContact}
                    onChange={this.onHandleChange}
                    onBlur={() => { this.unclicked() }}
                    readOnly={!this.state.editing}
                  /></div>
                </div></div>
                <div class="bottombar">
                  <div class={`message ${this.state.bodyState}`}
                    contentEditable
                    suppressContentEditableWarning={true}
                    name="body"
                    id="body"
                    onInput={this.onHandleChange}>
                    Message
                  </div>
                  <button class="send" type="submit" disabled={this.state.submitting}>
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </header>
      </>
    );
  }
}

export default SMSForm;