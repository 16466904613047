import React, { useRef, useState, useEffect } from 'react';
import './App.css';
import SMSForm from './components/SMSForm';
import { useGoogleLogin, GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import Reaptcha from 'reaptcha';

import { SignInButton } from "./components/SignInButton";
import { SignOutButton } from "./components/SignOutButton";
import { loginRequest } from './authConfig';
import { callMsGraph } from './graph';
import { ProfileData } from './components/ProfileData';

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';

//import Button from 'react-bootstrap/Button';

/**
* Renders information about the signed-in user or a button to retrieve data about the user
*/

var Buffer = require('buffer/').Buffer

function App() {
  console.log("Application Version : ", `${process.env.REACT_APP_VERSION}`)
  // const [verified, setVerified] = useState(false);
  // const captchaRef = useRef(null);
  // const [ user, setUser ] = useState([]);
  const [header, setHeader] = useState("");
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  let emailid;
  let id_name;

  const acquireAccessToken1 = async () => {

    try {
      // Silently acquires an access token
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      let accesstoken = response.accessToken;
      callMsGraph(accesstoken)
        .then((response) => {
          console.log("Response2 : ", response);
          emailid = response.userPrincipalName;
          id_name = response.displayName;
          localStorage.setItem("name", id_name);
          localStorage.setItem("email", emailid);
          //console.log(emailid, id_name);
          if (response.error) {
            //console.log("ABOVE ERROR");
            setHeader("Login Failed : " + response.error.code);
          } else {
            const token = accesstoken;
            //console.log("**************");
            console.log(token);
            //console.log("**************");
            //console.log(token);
            setAccessToken(token);
            //const parsed = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
            //console.log(parsed);

            verifyEmail(id_name, token, "microsoft");
          }
        })
          .catch(error => {
            console.log(error);
            setHeader(`Failed to acquire access token`);
          });
       

      //const token = 'EwBoA8l6BAAUAOyDv0l6PcCVu89kmzvqZmkWABkAAVCX2WH2kS1K1JQ0QMQzJI1+ijOx9w6fn5eG4zcawf/wv+YUQqz8eCOzKpfoRXk9f+J/7MOqkoMythoVCCOvF63wj5+KSYVs1tZVNwsYsr8dqFUAD7/8TGWskLVGWH0UbFHTh/ETVzbgEkMUTbtGkW0zlSba1QmgCgOm3TFmiS3J/ag2VKi9YQazkGXQYrLsfftJd6zqEtBy+Xg2iDd5lclmFS7r2y1OqwWwQvXwRUGHggphEqrYWntsP2xOT6iQ9Mamv4ojU2E9Iss31MqjKnmdrc0x/8pWP+4gKDUaOy8yQKz8Fj0vJvH17aw5BFVAOy1v2UvSVJaDTiYSrHop3r8DZgAACB964EU1DQrCOAKhsjezF/8ZsfEqZSGy/pkG6/tDKcBd3Fbmte9KsNJeTNKnQjQbXfE3udueRmkaSx1PvOVpElbRi5Sa+jcHToKKGx5tOqDJtRAv0lKhBowtZXE2RVP155y5FBad9BZXuRe2GDBQIdA2hKAQ6InAvq/pyK7SBqGRJoKctzoM2770H1nTV/xmPkcCovnjNL46KMb5QffsL+gwIIho7enLxXH0tuRuWBGD5qFIx5Omk3oPiIcAGtaIqUAi3wnnn1r2zPp0ucvpXLjXCesLYv7Bjsl9G0Xn/GSR/Ln0KaJEPC8il+3gbQ0imY7/RSjXOmE5wQbC5kg5bTEoE7eCmCPa7JtmhDW9qbKmN/LmOc8UMoQabt9/zXjDalxkG8vP/EIAfsF2UT8mi4whH4FpSs78gil8KSoGGFFqF76xP5FG2L0bFskWogbq89LE858TRECrfpRJXOSP6SRYcFK9WDRinlw2RFGwAsjmUb303IvtB5PZJ+nN41B24TOInTb9F4fJRamisRJJcQOVktrLrIN/8pqJTH6kgfwOXh0g+j083AC6lLKVrOMEYQdI4CbpfkWTx1ivP03FGUSPSIpCPYaf7lNy4kEkgb7waVeBhppSsRuyoOKIkeb399BFUgSBPUUIl3V9xhofsMit0uT0tkh2DbfLJmqOPy7nFB0TFubLtNg5j8BQGxGaYBGDRyZdf999WRgH6cRmZiZHQI2jk4zUfX8ajJZlIgxfAd8fV0G8mbuw8WP4N65qdth/dQI=';


    } catch (error) {
      console.error('Failed to acquire access token:', error);
    }
  };

  useEffect(() => {
    const loggedInUser = localStorage.getItem("data");
    //console.log(loggedInUser);
    //console.log("Old user exists")
    if (loggedInUser) {
      const olddate = new Date(localStorage.getItem("timestamp"));
      if ((new Date() - olddate) / (1000 * 60 * 60) > 1) { // one hour
        localStorage.clear();
      } else {
        const name = localStorage.getItem("name");
        const foundUser = JSON.parse(loggedInUser);
        setLoad(<div class="main"><SMSForm phone={foundUser.contact_number} id={foundUser.user_id} name={name} />
          <button id="logout" onClick={() => { logout() }}>Log Out</button>
        </div>);
      }
    }
    const acquireAccessToken = async () => {
      try {
        // Silently acquires an access token
        const response = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });

        let accesstoken = response.accessToken;
        callMsGraph(accesstoken)
          .then((response) => {
            console.log("Response2 : ", response);
            emailid = response.userPrincipalName;
            id_name = response.displayName;
            localStorage.setItem("name", id_name);
            localStorage.setItem("email", emailid);
            //console.log(emailid, id_name);
            if (response.error) {
             
              setHeader("Login Failed : " + response.error.code);
            } else {
              const token = accesstoken;
              
              console.log(token);
              setAccessToken(token);


              verifyEmail(id_name, token, "microsoft");
            }
          })

          .catch(error => {
            console.log(error);
            setHeader(`Failed to acquire access token`);
          });



      } catch (error) {
        console.error('Failed to acquire access token:', error);
        
      }
    };

    // Acquire the access token when the component is mounted
    acquireAccessToken();
  }, [instance, accounts]);



  const [load, setLoad] = useState(
    <div class="login">
      <AuthenticatedTemplate>


        <button class="bsk-btn bsk-btn-default" id="Sign In" style={{
          backgroundColor: 'white',
          height: '49px'
        }} onClick={() => { acquireAccessToken1() }}  > <object type="image/svg+xml" data="https://s3-eu-west-1.amazonaws.com/cdn-testing.web.bas.ac.uk/scratch/bas-style-kit/ms-pictogram/ms-pictogram.svg" class="x-icon"></object>
          &nbsp; Sign in with Microsoft </button>


      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>

        <center>

          <SignInButton />

        </center>

      </UnauthenticatedTemplate>
      <br></br>
      <br></br>
      <GoogleLogin
        onSuccess={credentialResponse => {
          //console.log(credentialResponse);
          parseToken(credentialResponse.credential);
        }}

        onError={() => {
          //console.log('Login Failed');
          setHeader("Login failed. Please try again.")
        }}
        size='large'

      />
    </div>
  );

  function parseToken(token) {
    const parsed = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
    localStorage.setItem("name", parsed.name);
    verifyEmail(parsed.name, token);
    return parsed;
  }


  const logout = () => {
    // setVerified(false);
    // setUser([]);
    // setProfile([]);
    localStorage.clear();
    setLoad(
      <div class="login">
        <AuthenticatedTemplate>
          <button class="bsk-btn bsk-btn-default" id="Sign In" style={{
            backgroundColor: 'white',
            height: '49px'
          }}  onClick={() => { acquireAccessToken1() }}  > <object type="image/svg+xml" data="https://s3-eu-west-1.amazonaws.com/cdn-testing.web.bas.ac.uk/scratch/bas-style-kit/ms-pictogram/ms-pictogram.svg" class="x-icon"></object>
            &nbsp; Sign in with Microsoft </button>


        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>

          <center>

            <SignInButton />

          </center>

        </UnauthenticatedTemplate>
        <br></br>
        <br></br>
        <GoogleLogin
          onSuccess={credentialResponse => {
            //console.log(credentialResponse);
            parseToken(credentialResponse.credential);
          }}

          onError={() => {
            //console.log('Login Failed');
            setHeader("Login Failed. Please try again.")
          }}
          size='large'
        />
      </div>
    )
  }

  const verifyEmail = (name, token, loginfrom) => {
    fetch(process.env.REACT_APP_API_URL + '/db/email', {
      method: 'POST',
      headers: {
        //'gfg_token_header_key': process.env.REACT_APP_JWT_TOKEN_KEY,
        'Content-Type': 'application/json',
        "google_token": token
      },
      body: JSON.stringify({
        token: token,
        loginfrom: loginfrom
      })
    })
      .then(res => {
        for (let entry of res.headers) { // <-- response header iterable
          //console.log(entry);
        }
        return res.json();
      })
      //.then(res => res.json())
      .then(data => {
        console.log("fetching");
       
        //console.log(data.out);
        if (data.out.length > 0) {
          localStorage.setItem("data", JSON.stringify(data.out[0]));
          localStorage.setItem("timestamp", new Date().toISOString());
          localStorage.setItem("gfg_token_header_key", data.gfg_token_header_key);
          setHeader("")
          setLoad(
            <div class="main">
              <SMSForm phone={data.out[0].contact_number} id={data.out[0].user_id} name={name} gfg_token_header_key={data.gfg_token_header_key} />
              <button id="logout" onClick={() => { logout() }}>Log Out</button>
            </div>
          );
        }
        else {
          setHeader("Sorry, your email isn't registered with this company. Try a different account.");
        }
      })
      .catch(err => {
        console.log(err);
        const email = localStorage.getItem("email");
        const message = `\n\nSorry, ${email} isn't registered.\n \nKindly Logout and Try a different account`;

        setHeader(
          <pre style={{ whiteSpace: 'pre-wrap' }}>
            {message}
          </pre>
        );

        setLoad(
          <SignOutButton />
        )
      })
  }

  return (
    <div className="App">
      <header className="App-header bgimage">

        <h5 class="login">
          <div class="loginbox">
            <br></br><br></br><br></br> </div>
          <h7><i>Business Messaging from VOIP Connections</i></h7><br></br>
          {header}

        </h5>
        {load}

      </header>
    </div>
  );
}

export default App;
