import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

//import Button from 'react-bootstrap/Button';
import { App } from '../App';
/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 * Note the [useMsal] package 
 */

export const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = async (loginType) => {
    try {
      if (loginType === "popup") {
        instance.loginPopup(loginRequest).catch((e) => {
          console.log(e);
        });
      } else if (loginType === "redirect") {
        instance.loginRedirect(loginRequest).catch((e) => {
          console.log(e);
        });
      }
      // After successful login, acquire the access token
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: instance.getActiveAccount(), // Get the current signed-in account
      });

      // Access the access token from the response
      const accessToken = response.accessToken;
      console.log('Access Token:', accessToken);
     
      // You can use the accessToken for your needs here
  } catch (e) {
    console.error('Failed to log in or acquire access token:', e);
  }

  };
  return (
    <button class="bsk-btn bsk-btn-default" id="Sign In" style={{
      backgroundColor: 'white',
      height: '49px'
    }}  onClick={() => { handleLogin("redirect") }}  > <object type="image/svg+xml" data="https://s3-eu-west-1.amazonaws.com/cdn-testing.web.bas.ac.uk/scratch/bas-style-kit/ms-pictogram/ms-pictogram.svg" class="x-icon"></object> 
     &nbsp; Sign in with Microsoft </button>
   
    
    
  );
};